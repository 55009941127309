import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { useEffect, useMemo, useState } from "react";
import ExpanderList from "@rio-cloud/rio-uikit/components/expander/ExpanderList";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData, setListItemsForChart } from "../../../stores/customReport/customReportPageSlice";

const generateDynamicRanges = (data: any[], numberOfRanges: number) => {
	const min = Math.min(...data.map((item) => item.value));
	const max = Math.max(...data.map((item) => item.value));
	const rangeSize = Math.ceil((max - min) / numberOfRanges);

	let ranges = [];
	let start = min;

	for (let i = 0; i < numberOfRanges; i++) {
		const end = start + rangeSize;
		ranges.push({ min: start, max: end });
		start = end + 1;
	}

	return ranges;
};

const getRangeLabel = (ranges: any[], value: number) => {
	for (const range of ranges) {
		if (value >= range.min && value <= range.max) {
			return `${range.min}-${range.max}`;
		}
	}
	return `${ranges[ranges.length - 1].max}+`;
};

const sortGroupedData = (groupedData: any[]) => {
	return groupedData.sort((a, b) => {
		const aMin = parseInt(a.name.split("-")[0], 10);
		const bMin = parseInt(b.name.split("-")[0], 10);
		return aMin - bMin;
	});
};

const CustomReportPieChart = ({ chartData }: any) => {
	const { typeOfChart, stateViewChart } = useAppSelector(getCustomReportPageData);
	const dispatch = useAppDispatch();
	const numberOfRanges = 7;

	const { groupedData } = useMemo(() => {
		const originalData = chartData.chart[0]?.axisY?.map((item: any) => ({
			name: item.name,
			value: parseFloat(item.value),
		}));

		if (!originalData || originalData.length === 0) return { groupedData: [] };

		const sortedData = originalData.sort((a: { value: number }, b: { value: number }) => b.value - a.value);
		const topData = sortedData.slice(0, stateViewChart);

		const ranges = generateDynamicRanges(topData, numberOfRanges);
		const groupMap: { [key: string]: any[] } = {};

		topData.forEach((item: any) => {
			const rangeLabel = getRangeLabel(ranges, item.value);
			if (!groupMap[rangeLabel]) {
				groupMap[rangeLabel] = [];
			}
			groupMap[rangeLabel].push(item);
		});

		let groupedData = Object.keys(groupMap).map((rangeLabel) => ({
			name: rangeLabel,
			value: groupMap[rangeLabel].reduce((acc, item) => acc + item.value, 0),
			items: groupMap[rangeLabel],
		}));

		groupedData = sortGroupedData(groupedData);

		return { groupedData };
	}, [chartData, stateViewChart]);

	const chartColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28FD0"];

	const listItems = groupedData.map((group, index) => ({
		id: index + 1,
		header: `Range: ${group.name}`,
		open: index === 0,
		body: (
			<ul className="list-group">
				{group.items.map((item: any, idx: number) => (
					<li key={idx} className="display-flex align-items-center gap-5 padding-5">
						<span className="rioglyph rioglyph-truck" />
						<span>{item.name}: </span>
						<span className="badge" style={{ background: chartColors[idx % chartColors.length] }}>
							{item.value}
						</span>
					</li>
				))}
			</ul>
		),
	}));

	return (
		<div className="display-flex align-items-start justify-content-center">
			<ResponsiveContainer width={"99%"} height={300}>
				<PieChart margin={{ top: 40 }}>
					<Pie
						data={groupedData}
						isAnimationActive={false}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						outerRadius={100}
						fill="#8884d8"
						label={({ name, value }: any) => `${name}: ${value}`}
					>
						{groupedData.map((entry: any, index: number) => (
							<Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
			<div className="width-30pct height-350 padding-right-50 overflow-y-hidden overflow-y-auto">
				<ExpanderList items={listItems} className="margin-bottom-20" />
			</div>
		</div>
	);
};

export default CustomReportPieChart;
