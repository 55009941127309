import { ContentLoader } from "@rio-cloud/rio-uikit";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { useGetSingleChartByIdMutation, useUpdateSingleChartMutation } from "../../../services/customReportApi";
import { getAppData } from "../../../stores/app/appSlice";
import {
	getCustomReportPageData,
	setActiveChart,
	setTypeOfChart,
	updateChartFromList,
} from "../../../stores/customReport/customReportPageSlice";
import ChartItem from "../grid/ChartItem";

const ChartStructure = ({ openChart, chart }: any) => {
	const dispatch = useAppDispatch();
	const [chartData, setChartData] = useState<any>(null);
	const [loadingChart, setLoadingChart] = useState<boolean>(true);
	const { dateTime, tags, assets } = useAppSelector(getAppData);
	const { typeOfChart } = useAppSelector(getCustomReportPageData);
	const [updateChart] = useUpdateSingleChartMutation();
	const [getSingleChartById, { isLoading }] = useGetSingleChartByIdMutation();

	useEffect(() => {
		const fetchChartData = async () => {
			if (openChart && chart) {
				setLoadingChart(true);

				try {
					const fetchedChart = await getSingleChartById({
						customId: chart.id,
						requestPayload: {
							assetIds: assets,
							tagIds: tags,
							startAt: dateTime.start,
							endAt: dateTime.end,
							zone: "UTC-3",
							infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
							search: "",
							page: 0,
							pageSize: 50,
						},
					}).unwrap();
					setChartData(fetchedChart);
				} catch (error) {
					console.error("Error fetching chart data:", error);
				} finally {
					setLoadingChart(false);
				}
			}
		};

		fetchChartData();
	}, [openChart, chart, getSingleChartById, assets, tags, dateTime, dispatch]);

	useEffect(() => {
		const updateAndRefetchChart = async () => {
			if (typeOfChart && chartData?.chartType !== typeOfChart) {
				try {
					const updatedChartData = {
						...chartData,
						id: chart.id,
						chartType: typeOfChart,
						complementaryData:
							chart.complementaryData === "ALL_INFRACTIONS" || chart.complementaryData === "" ? null : chart.complementaryData,
						startAt: dateTime.start,
						endAt: dateTime.end,
					};

					await updateChart([updatedChartData]).unwrap();

					const fetchedChart = await getSingleChartById({
						customId: updatedChartData.id,
						requestPayload: {
							assetIds: assets,
							tagIds: tags,
							startAt: dateTime.start,
							endAt: dateTime.end,
							zone: "UTC-3",
							infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
							page: 0,
							pageSize: 50,
							search: "",
						},
					}).unwrap();

					setChartData(fetchedChart);
					dispatch(setActiveChart(fetchedChart));
				} catch (error) {
					console.error("Error updating and re-fetching chart data:", error);
				}
			}
		};

		updateAndRefetchChart();
	}, [typeOfChart]);

	return <>{loadingChart ? <ContentLoader height={400} width={"100%"} /> : <ChartItem chart={chartData} isLoading={isLoading} />}</>;
};

export default ChartStructure;
