import ExpanderList from "@rio-cloud/rio-uikit/components/expander/ExpanderList";
import { differenceInDays, parseISO } from "date-fns";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { chartColors } from "../../../constants/charts";
import { getAppData } from "../../../stores/app/appSlice";

const MyBarChart = ({ chartData }: any) => {
	const { dateTime } = useAppSelector(getAppData);
	const startDate = parseISO(dateTime.start);
	const endDate = parseISO(dateTime.end);
	const dateDifferenceInDays = differenceInDays(endDate, startDate);

	const convertToMinutes = (timeString: any) => {
		const [hours, minutes] = timeString.split(":").map(Number);
		return hours * 60 + minutes;
	};

	const transformedData = chartData.chart.map((item: any) => {
		const totalEntry: any = item.axisY.find((entry: any) => entry.name === "Total");
		return {
			axisX: item.axisX,
			Total: convertToMinutes(totalEntry.value),
		};
	});

	const isADay = dateDifferenceInDays === 0;

	const listItems = chartData.chart.map((item: any, index: number) => ({
		id: index + 1,
		header: `Data for ${item.axisX}`,
		open: isADay && index === 0,
		body: (
			<ul className="list-group">
				{item.axisY
					.filter((axisYItem: any) => axisYItem.name !== "Total")
					.map((axisYItem: any, idx: number) => (
						<div key={idx} className="display-flex align-items-center gap-5 padding-5">
							<span className="rioglyph rioglyph-truck" /> ({idx})<li className="">{axisYItem.name}:</li>
							<div className="flex-1-1-0">
								<span
									className="badge"
									style={{
										background: isADay ? chartColors[idx % chartColors.length] : chartColors[0],
									}}
								>
									{axisYItem.value}
								</span>
							</div>
						</div>
					))}
			</ul>
		),
	}));

	const shouldRotate = true;

	return (
		<div className="display-flex align-items-start justify-content-center padding-x-50">
			<ResponsiveContainer width={"100%"} height={400}>
				<BarChart data={transformedData} margin={{ top: 20, right: 30, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						textAnchor={shouldRotate ? "end" : "middle"}
						dataKey="axisX"
						angle={shouldRotate ? -45 : 0}
						height={shouldRotate ? 110 : undefined}
						tickMargin={10}
						interval={0}
					/>
					<YAxis label={{ value: "Minutes", angle: -90, position: "insideLeft" }} />
					<Tooltip />
					<Bar dataKey="Total" fill="#8884d8" />
				</BarChart>
			</ResponsiveContainer>
			<div className="width-30pct height-350 overflow-y-auto">
				<ExpanderList items={listItems} />
			</div>
		</div>
	);
};

export default MyBarChart;
