import ResponsiveColumnStripe from "@rio-cloud/rio-uikit/ResponsiveColumnStripe";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData, setActiveChart, setTypeOfChart } from "../../../stores/customReport/customReportPageSlice";
import { FormattedMessage } from "react-intl";

const ChartStripe = ({ setChartSelected, setOpenChart }: any) => {
	const { activeChart, chartsFetched } = useAppSelector(getCustomReportPageData);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (chartsFetched.length > 0) {
			const isActiveChartDeleted = !chartsFetched.find((chart: { id: string }) => chart.id === activeChart.id);

			if (isActiveChartDeleted || !activeChart.id) {
				setChartSelected(chartsFetched[0]);
				dispatch(setActiveChart(chartsFetched[0]));
				setOpenChart(true);
			}
		}
	}, [chartsFetched, activeChart, dispatch, setChartSelected, setOpenChart]);

	return (
		<div className="height-100pct">
			<ResponsiveColumnStripe
				className="bg-white rounded"
				columnsWrapperClassName="margin-0 padding-0 space-x-1 user-select-none"
				buttonClassName="hover-bg-highlight-decent"
				minColumnWith={250}
				minColumns={1}
				maxColumns={3}
				stretchLastItems={false}
				asType="ul"
				activePage={0}
			>
				{chartsFetched?.map((item: { id: string; chartName: string; mainData: string }, index: number) => (
					<div
						key={index}
						className="cursor-pointer min-width-400"
						onClick={() => {
							setChartSelected(item);
							setOpenChart(true);
							dispatch(setActiveChart(item));
						}}
					>
						<ChartStripeIndividual chart={item} active={item.id == activeChart.id || (activeChart.id == undefined && index == 0)} />
					</div>
				))}
			</ResponsiveColumnStripe>
		</div>
	);
};

export default ChartStripe;

const ChartStripeIndividual = ({ chart, active }: any) => {
	const chartIconClasses: Record<string, string> = {
		IT_KICKDOWN: "rioglyph-truck text-color-info",
		SPEED: "rioglyph-speed text-color-secondary",
		ENGINE_BRAKE: "rioglyph-brake text-color-warning",
		DISTANCE: "rioglyph-delivery text-color-success",
		RPM: "rioglyph-speed text-color-info",
		TRIP: "rioglyph-warning-sign text-color-secondary",
		GEAR: "rioglyph-merge",
	};

	const getChartIcon = (chartComplement: string, mainData: string) => {
		const iconKey = chartComplement || mainData;
		const iconClass = chartIconClasses[iconKey] || "rioglyph-truck text-color-secondary";
		const [baseIconClass, colorClass] = iconClass.split(" ");

		return `${baseIconClass} ${active ? "text-color-white" : colorClass}`;
	};

	const getTranslateComplementary = (key: string) => {
		switch (key) {
			case "IT_KICKDOWN": {
				return <FormattedMessage id="general.app.infraction.IT_KICKDOWN" />;
			}
			case "IT_SPEED": {
				return <FormattedMessage id="general.app.infraction.IT_SPEED" />;
			}
			case "IT_ACCELERATION": {
				return <FormattedMessage id="general.app.infraction.IT_ACCELERATION" />;
			}
			case "IT_BRAKING": {
				return <FormattedMessage id="general.app.infraction.IT_BRAKING" />;
			}
			case "GEAR": {
				return <FormattedMessage id="customReport.graph.GEAR" />;
			}
			default: {
				return key;
			}
		}
	};

	const getTranslateMain = (key: string) => {
		switch (key) {
			case "INFRACTIONS": {
				return <FormattedMessage id="customReport.graph.INFRACTIONS" />;
			}
			default: {
				return key;
			}
		}
	};

	return (
		<div
			className={`padding-25 display-flex line-height-20 align-items-center justify-content-between border-right-only border ${active ? "bg-highlight" : ""}`}
		>
			<div className="display-flex flex-column width-100pct">
				<h5>{chart.chartName}</h5>
				<div className="display-flex">
					<div className="space-y-5 text-size-14">
						{chart.complementaryData && (
							<div className="display-flex flex-row justify-content-start gap-10">
								<span className={active ? "text-color-white" : "text-color-dark"}>Complemento</span>
								<span>
									<strong>{getTranslateComplementary(chart.complementaryData)}</strong>
								</span>
							</div>
						)}
						<div className="display-flex flex-row justify-content-start gap-10">
							<span className={active ? "text-color-white" : "text-color-dark"}>Assunto</span>
							<span className={`text-medium ${active ? "text-color-white" : "text-color-gray"}`}>{getTranslateMain(chart.mainData)}</span>
						</div>
					</div>
				</div>
			</div>
			<span style={{ fontSize: "6rem" }} className={`rioglyph ${getChartIcon(chart.complementaryData, chart.mainData)}`} />
		</div>
	);
};
