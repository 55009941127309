import { Button } from "@rio-cloud/rio-uikit";
import ButtonDropdown from "@rio-cloud/rio-uikit/lib/es/ButtonDropdown";
import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog";
import { differenceInDays } from "date-fns";
import React, { useState } from "react";
import DateSelector from "../../../components/common/date/DateSelector";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getAppData } from "../../../stores/app/appSlice";
import {
	getCustomReportPageData,
	setActiveChart,
	setOpenCustomReportSidesheet,
	setStateViewChart,
	setTypeOfChart,
} from "../../../stores/customReport/customReportPageSlice";

type ChartHeaderProps = {
	chartFiltered: any;
	onDelete: (id: string) => void;
};

const ChartHeader: React.FC<ChartHeaderProps> = ({ chartFiltered, onDelete }) => {
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const dispatch = useAppDispatch();
	const chartId = chartFiltered.id;
	const { dateTime } = useAppSelector(getAppData);
	const { typeOfChart, stateViewChart, activeChart } = useAppSelector(getCustomReportPageData);

	const chartTypeTitles: Record<string, string> = {
		BAR: "Barra",
		PIE: "Pizza",
		...(activeChart.mainData !== "HISTOGRAM" && { LINE: "Linha" }),
	};

	const stateViewChartTitles: Record<number, string> = {
		50: "Mostrando 50 infratores",
		100: "Mostrando 100 infratores",
		200: "Mostrando 200 infratores",
	};

	const testItems = [
		{
			value: `Mostrando 50 infratores`,
			onSelect: () => dispatch(setStateViewChart(50)),
		},
		{
			value: `Mostrando 100 infratores`,
			onSelect: () => dispatch(setStateViewChart(100)),
		},
		{
			value: `Mostrando 200 infratores`,
			onSelect: () => dispatch(setStateViewChart(200)),
		},
	];

	const chartTypes = [
		{
			value: `Barra`,
			onSelect: () => dispatch(setTypeOfChart("BAR")),
		},
		{
			value: `Pizza`,
			onSelect: () => dispatch(setTypeOfChart("PIE")),
		},
		...(activeChart.mainData !== "HISTOGRAM"
			? [
					{
						value: `Linha`,
						onSelect: () => dispatch(setTypeOfChart("LINE")),
					},
				]
			: []),
	];

	const dateDifferenceInDays = differenceInDays(dateTime.end, dateTime.start);

	const handleDeleteConfirm = () => {
		onDelete(chartId);
		setShowDeleteDialog(false);
		setActiveChart(1);
	};

	return (
		<div className="display-flex justify-content-between items-center gap-20">
			<div className="width-100pct display-flex gap-25">
				<div className="display-flex items-center gap-20">
					<div className="width-400">
						<DateSelector />
					</div>
				</div>
				<div className="display-flex gap-5">
					{typeOfChart === "BAR" && dateDifferenceInDays === 0 && (
						<ButtonDropdown title={stateViewChartTitles[stateViewChart]} items={testItems} />
					)}
					{typeOfChart === "PIE" && <ButtonDropdown title={stateViewChartTitles[stateViewChart]} items={testItems} />}
					<ButtonDropdown variant={Button.VARIANT_OUTLINE} title={chartTypeTitles[activeChart.chartType]} items={chartTypes} />
				</div>
			</div>
			<div className="display-flex gap-5">
				<Button iconOnly iconName="rioglyph-trash" onClick={() => setShowDeleteDialog(true)} />
				<Button
					iconOnly
					iconName="rioglyph-pencil"
					onClick={() => dispatch(setOpenCustomReportSidesheet({ open: true, chart: chartFiltered }))}
				/>
			</div>
			<ConfirmationDialog
				show={showDeleteDialog}
				title="Deletar gráfico"
				content={`Você tem certeza que deseja deletar o gráfico ${chartFiltered.chartName}?`}
				bsSize="sm"
				onClickConfirm={handleDeleteConfirm}
				onClickCancel={() => setShowDeleteDialog(false)}
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				useOverflow
			/>
		</div>
	);
};

export default ChartHeader;
