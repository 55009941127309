import { useState } from "react";
import ChartStripe from "../grid/ChartStripe";
import ChartStructure from "./ChartStructure";

const GridStatic = () => {
	const [openChart, setOpenChart] = useState(true);
	const [chartSelected, setChartSelected] = useState({});

	return (
		<>
			<ChartStripe setChartSelected={setChartSelected} setOpenChart={setOpenChart} />
			<ChartStructure openChart={openChart} setOpenChart={setOpenChart} chart={chartSelected} />
		</>
	);
};
export default GridStatic;
