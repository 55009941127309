import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";
import { customReportApi } from "../../services/customReportApi";

interface CustomReportPageState {
	chartsFetched: any[];
	stateViewChart: number;
	updatedChart: any | {};
	typeOfChart: string | null;
	itemsForChart: any[];
	activeChart: any;
	customReportSidesheet: {
		open: boolean;
		chart: any | {};
	};
}

const initialState: CustomReportPageState = {
	customReportSidesheet: {
		open: false,
		chart: {},
	},
	itemsForChart: [],
	stateViewChart: 50,
	activeChart: {},
	chartsFetched: [],
	typeOfChart: null,
	updatedChart: {},
};

const customReportPageSlice = createSlice({
	name: "customReportPage",
	initialState,
	reducers: {
		setTypeOfChart: (state, action: PayloadAction<string | null>) => {
			state.typeOfChart = action.payload;
		},
		setActiveChart: (state, action: PayloadAction<any>) => {
			state.activeChart = action.payload;
		},
		setListItemsForChart: (state, action: PayloadAction<any>) => {
			state.itemsForChart = action.payload;
		},
		setOpenCustomReportSidesheet: (state, action: PayloadAction<{ open: boolean; chart: any | {} }>) => {
			state.customReportSidesheet = action.payload;
		},
		setStateViewChart: (state, action: PayloadAction<number>) => {
			state.stateViewChart = action.payload;
		},
		deleteChart: (state, action: PayloadAction<any>) => {
			state.chartsFetched = state.chartsFetched.filter((chart) => chart.id !== action.payload.id);
		},
		addChart: (state, action: PayloadAction<any>) => {
			state.chartsFetched = [...state.chartsFetched, action.payload]; // Create a new array
		},
		clearCharts: (state) => {
			state.chartsFetched = [];
		},
		updateChartFromList: (state, action: PayloadAction<{ id: string; updates: Partial<any> }>) => {
			const { id, updates } = action.payload;
			state.chartsFetched = state.chartsFetched.map((chart) => (chart.id === id ? { ...chart, ...updates } : chart));
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(customReportApi.endpoints.getListChartsIds.matchFulfilled, (state, action: PayloadAction<any[]>) => {
			state.chartsFetched = [...action.payload].sort((a, b) => a.viewOrder - b.viewOrder);
		});
	},
});

export const {
	setOpenCustomReportSidesheet,
	setActiveChart,
	setTypeOfChart,
	setListItemsForChart,
	setStateViewChart,
	deleteChart,
	addChart,
	clearCharts,
	updateChartFromList,
} = customReportPageSlice.actions;

export const getChartsFetched = (state: RootState) => state.pageCustomReport.chartsFetched;
export const getCustomReportSidesheet = (state: RootState) => state.pageCustomReport.customReportSidesheet;
export const getUpdatedChart = (state: RootState) => state.pageCustomReport.updatedChart;
export const getStateViewChart = (state: RootState) => state.pageCustomReport.stateViewChart;
export const getActiveChart = (state: RootState) => state.pageCustomReport.activeChart;
export const getCustomReportPageData = (state: RootState) => state.pageCustomReport;

export default customReportPageSlice.reducer;
