import { Spinner } from "@rio-cloud/rio-uikit";
import Button from "@rio-cloud/rio-uikit/Button";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../configuration/setup/hooks";
import { useGetListChartsIdsMutation } from "../../services/customReportApi";
import { getAppData } from "../../stores/app/appSlice";
import { getChartsFetched, setOpenCustomReportSidesheet } from "../../stores/customReport/customReportPageSlice";
import GridStatic from "./static/GridStatic";

const CustomReportPage = () => {
	const [getListCharts, { isLoading }] = useGetListChartsIdsMutation();
	const { assets, tags, dateTime } = useAppSelector(getAppData);
	const listCharts = useAppSelector(getChartsFetched);
	const dispatch = useAppDispatch();

	const payload = {
		assetIds: assets,
		tagIds: tags,
		startAt: dateTime.start,
		endAt: dateTime.end,
		zone: "UTC-3",
		infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
		search: "",
		page: 0,
		pageSize: 50,
	};

	const handleFetchInitialList = async () => {
		await getListCharts(payload);
	};

	useEffect(() => {
		handleFetchInitialList();
	}, []);

	return (
		<div className="display-flex gap-25 flex-column width-100pct">
			<Button className="max-width-150 height-35" onClick={() => dispatch(setOpenCustomReportSidesheet({ open: true, chart: null }))}>
				Criar gráfico
			</Button>
			{isLoading ? <Spinner /> : listCharts.length == 0 ? <div>Crie um gráfico</div> : <GridStatic />}
		</div>
	);
};

export default CustomReportPage;
