import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { chartColors } from "../../../constants/charts";
import ExpanderList from "@rio-cloud/rio-uikit/components/expander/ExpanderList";
import NoDataState from "../../../components/common/state/NoDataState";

const CustomReportLineChart = ({ chartData, statusValue }: any) => {
	if (chartData?.chart?.length === 0) {
		return (
			<div className="height-400">
				<NoDataState />;
			</div>
		);
	}

	const transformData = (rawData: any) => {
		const result: any = [];

		const uniqueDates = [...new Set(rawData?.map((item: any) => item.axisX))];

		uniqueDates.forEach((date) => {
			const entry: any = { axisX: date };

			const dataPoint = rawData?.find((item: any) => item.axisX === date);
			if (dataPoint) {
				dataPoint?.axisY?.forEach((y: any) => {
					entry[y.name] = parseFloat(y.value);
				});
			}

			result.push(entry);
		});

		return result;
	};

	const transformedData = transformData(chartData?.chart);

	const getLineColor = (index: any) => {
		const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#0088fe", "#00c49f"];
		return colors[index % colors.length];
	};

	const lineNames = chartData.chart[0]?.axisY.map((y: any) => y.name) || [];

	if (statusValue === "loading") {
		return (
			<div className="flex items-center justify-center h-full">
				<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
			</div>
		);
	}

	const rawData = [...(chartData?.chart || [])]
		.sort((a: any, b: any) => {
			const dateA = new Date(a.axisX.split("-").reverse().join("-"));
			const dateB = new Date(b.axisX.split("-").reverse().join("-"));
			return dateA.getTime() - dateB.getTime();
		})
		.map((item: any) => {
			return {
				...item,
				axisY: [...item.axisY].sort((a: any, b: any) => {
					return Number(b.value) - Number(a.value);
				}),
			};
		});

	const listItems = rawData.map((item, index) => ({
		id: index + 1,
		header: `Data for ${item.axisX}`,
		open: index == 0,
		body: (
			<ul className="list-group">
				{item.axisY.map((axisYItem: any, idx: number) => (
					<div className="display-flex align-items-center gap-5 padding-5">
						<span className="rioglyph rioglyph-truck" /> ({idx})
						<li key={idx} className="">
							{axisYItem.name}:
						</li>
						<div className="flex-1-1-0">
							<span className="badge" style={{ background: chartColors[0] }}>
								{axisYItem.value}
							</span>
						</div>
					</div>
				))}
			</ul>
		),
	}));

	return (
		<div className="display-flex align-items-start justify-content-center gap-50 padding-right-25">
			<ResponsiveContainer width="100%" height={400}>
				<LineChart
					data={transformedData}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 20,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="axisX" tick={{ fontSize: 12 }} padding={{ left: 20, right: 20 }} />
					<YAxis tick={{ fontSize: 12 }} />
					<Tooltip />
					<Legend verticalAlign="top" height={36} wrapperStyle={{ fontSize: "12px" }} />
					{lineNames.map((name: string, index: number) => (
						<Line
							key={name}
							type="monotone"
							dataKey={name}
							stroke={getLineColor(index)}
							strokeWidth={2}
							dot={{ r: 4 }}
							activeDot={{ r: 6 }}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
			<div className="width-30pct height-350 padding-right-50 overflow-y-hidden overflow-y-auto">
				<ExpanderList items={listItems} className="margin-bottom-20" />
			</div>
		</div>
	);
};

export default CustomReportLineChart;
