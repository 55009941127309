import ExpanderList from "@rio-cloud/rio-uikit/components/expander/ExpanderList";
import { differenceInDays, format, parse, parseISO, setHours, setMilliseconds, setMinutes, setSeconds, startOfDay } from "date-fns"; // Import necessary functions from date-fns
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import NoDataState from "../../../components/common/state/NoDataState";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { chartColors } from "../../../constants/charts";
import { getAppData, setAppDate } from "../../../stores/app/appSlice";
import { getStateViewChart } from "../../../stores/customReport/customReportPageSlice";

const CustomReportBarChart = ({ chartData }: any) => {
	const { dateTime } = useAppSelector(getAppData);
	const startDate = parseISO(dateTime.start);
	const endDate = parseISO(dateTime.end);
	const dateDifferenceInDays = differenceInDays(endDate, startDate);
	const dispatch = useAppDispatch();

	if (chartData?.chart?.length === 0) {
		return (
			<div className="height-400">
				<NoDataState />;
			</div>
		);
	}

	const rawData = [...(chartData?.chart || [])]
		.sort((a: any, b: any) => {
			const dateA = new Date(a.axisX.split("-").reverse().join("-"));
			const dateB = new Date(b.axisX.split("-").reverse().join("-"));
			return dateA.getTime() - dateB.getTime();
		})
		.map((item: any) => {
			return {
				...item,
				axisY: [...item.axisY].sort((a: any, b: any) => {
					return Number(b.value) - Number(a.value);
				}),
			};
		});

	const processedData = rawData.map((item: { axisX: any; axisY: any[] }) => {
		let sum = 0;

		item.axisY.forEach((axisYItem) => {
			sum += parseInt(axisYItem.value);
		});

		return {
			date: item.axisX,
			sum: sum,
		};
	});

	function calculateTop(data: any[], limit = 10) {
		const totalValues: any = {};
		const latestDate: any = {};

		data.forEach((entry: { axisX: any; axisY: any }) => {
			const { axisX, axisY } = entry;
			axisY.forEach((item: { name: any; value: string }) => {
				const name = item.name;
				const value = parseInt(item.value, 10);

				totalValues[name] = (totalValues[name] || 0) + value;

				if (!latestDate[name] || new Date(axisX) > new Date(latestDate[name])) {
					latestDate[name] = axisX;
				}
			});
		});

		const sortedFunctions = Object.entries(totalValues)
			.sort((a: any, b: any) => b[1] - a[1])
			.slice(0, limit);

		const result: any[] = [];

		sortedFunctions.forEach(([name, total]: any) => {
			const axisX = latestDate[name];
			const existingEntry = result.find((entry) => entry.date === axisX);

			if (existingEntry) {
				existingEntry[name] = total;
			} else {
				result.push({
					date: axisX,
					[name]: total,
				});
			}
		});

		return result;
	}

	const isMoreThanOneDay = dateDifferenceInDays > 1;
	const isADay = dateDifferenceInDays === 0;

	const stateViewChart = useAppSelector(getStateViewChart);

	const chartDataToRender = isADay ? calculateTop(rawData, stateViewChart) : processedData;
	const isLargeData = isADay ? Object.keys(chartDataToRender[0]).length > 20 : chartDataToRender.length > 10;
	const showLegendLargeGroup = isADay ? Object.keys(chartDataToRender[0]).length : processedData.length > 20;

	const shouldRotate: boolean = isLargeData && dateDifferenceInDays > 5;

	const handleBarClick = (data: any) => {
		if (data && data.activeLabel) {
			const clickedDate = data.activeLabel;
			const parsedDate = parse(clickedDate, "dd-MM-yyyy", new Date());
			const dateWithTime = setHours(setMinutes(setSeconds(setMilliseconds(parsedDate, 999), 59), 59), 23);
			const formattedEndDate = format(dateWithTime, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
			const startOfDayDate = startOfDay(parsedDate);
			const formattedStartDate = format(startOfDayDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

			dispatch(
				setAppDate({
					start: formattedStartDate,
					end: formattedEndDate,
				}),
			);
		}
	};

	const allKeys = chartDataToRender.reduce((acc: any, item: any) => {
		Object.keys(item).forEach((key: any) => {
			if (key !== "date" && !acc.includes(key)) {
				acc.push(key);
			}
		});
		return acc;
	}, []);

	const listItems = rawData.map((item, index) => ({
		id: index + 1,
		header: `Data for ${item.axisX}`,
		open: isADay && index == 0,
		body: (
			<ul className="list-group">
				{item.axisY.map((axisYItem: { name: string; value: string }, idx: number) => (
					<div className="display-flex align-items-center gap-5 padding-5">
						<span className="rioglyph rioglyph-truck" /> ({idx})
						<li key={idx} className="">
							{axisYItem.name}:
						</li>
						<div className="flex-1-1-0">
							<span className="badge" style={{ background: isADay ? chartColors[idx % chartColors.length] : chartColors[0] }}>
								{axisYItem.value}
							</span>
						</div>
					</div>
				))}
			</ul>
		),
	}));

	return (
		<div className="display-flex align-items-start justify-content-center gap-50 padding-right-25">
			<ResponsiveContainer width="100%" height={400} className={"bg-white"}>
				<BarChart data={chartDataToRender} onClick={handleBarClick}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						textAnchor={shouldRotate ? "end" : "middle"}
						tickMargin={10}
						dataKey="date"
						interval={0}
						angle={shouldRotate ? -45 : 0}
						height={shouldRotate ? 70 : undefined}
					/>
					<YAxis domain={[0, "dataMax + 10"]} />
					<Tooltip shared={isMoreThanOneDay || shouldRotate} />
					{isADay && !showLegendLargeGroup && <Legend />}
					{allKeys.map((key: any, index: number) => (
						<Bar
							width={200}
							key={index}
							dataKey={key}
							maxBarSize={80}
							stackId={isMoreThanOneDay || shouldRotate ? "a" : undefined}
							fill={isMoreThanOneDay || shouldRotate ? "#67abc5" : chartColors[index % chartColors.length]}
						>
							<LabelList
								dataKey={key}
								position="center"
								content={(props) => {
									const { x, y, width, value } = props;
									const adjustedX = isADay ? Number(x) + 7 : Number(x) + Number(width) / 2;
									const adjustedY = Number(y) - 10;
									return (
										<text
											fontSize={isLargeData ? 11 : 14}
											x={adjustedX}
											y={adjustedY}
											fill="#000"
											textAnchor="middle"
											dominantBaseline="middle"
										>
											{isADay ? index + 1 : value}
										</text>
									);
								}}
							/>
						</Bar>
					))}
				</BarChart>
			</ResponsiveContainer>
			<div className="width-30pct height-350 padding-right-50 overflow-y-hidden overflow-y-auto">
				<ExpanderList items={listItems} className="margin-bottom-20" />
			</div>
		</div>
	);
};

export default CustomReportBarChart;
